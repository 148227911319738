@charset "utf-8";

#site-header{
	width: 100%;
	height: $header-pc;
	background: #FFF;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	padding: 0 40px;
	padding-right: 0;
	@include flex();
	@include transition(400ms , $delay:400ms);
	align-items: center;
	@media screen and (max-width:$responsive){
		height: $header-sp;
		padding: 0 0 0 15px;
	}
	&.hidden{
		top: -$header-pc;
		@include transition(400ms);
	}
	.logo{
		a{
			float: left;
			img{
				height: 48px;
				@media screen and (max-width:$responsive){
					height: 32px;	
				}
			}
		}
		h1{
			float: left;
			font-size: 12px;
			font-weight: 700;
			white-space: nowrap;
			margin: 13px 0 0 24px;
			@media screen and (max-width:$responsive){
				font-size: 10px;
				margin: 6px 0 0 12px;
			}
		}
	}
	.gnavi{
		&__list {
			float: left;
			@include flex();
			align-items: center;
			> li{
				list-style: none;
				float: left;
				margin: 0 48px 0 0;
				@media screen and (max-width:$responsive){
					display: none;
				}
				&:last-child{
					margin: 0;
				}
				&.contact{
					margin: 0 0 0 8px;
					@media screen and (max-width:1200px){
						margin: 0;
					}
					@media screen and (max-width:$responsive){
						display: block;	
					}
					a{
						display: block;
						text-align: center;
						height: $header-pc;
						background: $normal;
						color: #FFF;
						font-weight: 500;
						font-size: 14px;
						padding: 0 32px;
						@include transition(200ms);
						@media screen and (max-width:$responsive){
							height: $header-sp;
							padding: 0 16px;
							font-size: 9px;
						}
						&:hover{
							color: #FFF;
							background: lighten($normal , 16%);
						}
						i{
							display: block;
							padding: 16px 0 6px;
							font-size: 18px;
							@media screen and (max-width:$responsive){
								font-size: 14px;
								padding-top: 14px;
							}
						}
					}
				}
				&.social-link{
					@media screen and (max-width:1200px){
						display: none;
					}
				}
				.sns{
					@media screen and (max-width:1200px){
						display: none;
					}
					li{
						float: left;
						margin: 0 32px 0 0;
						&:last-child{
							margin: 0;
						}
					}
				}
				a{
					font-size: 15px;
					color: $normal;
					font-weight: 700;
					&:hover{
						color: lighten($normal,40%);
					}
				}
			}
		}

	}
}